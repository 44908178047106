/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import { useHistory, Prompt, useLocation } from 'react-router-dom';
import {
  formatMessageDoc,
  fromJSONToNode,
  LONG_GTFS_TEXT_REPLACEMENTS,
} from '@mta-live-media-manager/shared';

import { Tweet } from 'components/common/tweets/tweet-input';
import { useTweetThreadState } from 'components/common/tweets/tweet-thread-input';
import {
  AlertFormHeading,
  AlertMessageLeader,
  AlertWebAndApps,
  AlertEmailAndSubject,
  AlertFormButtons,
  checkIfDraft,
} from 'components/common/alert-form';
import { getDefaultAffectedStations } from 'components/common/connected-route-range-stop-selector-group';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { getAlertDefaultScreenTargetTypes } from 'utils/screen-targeting';
import { DISCARD_CHANGES_MESSAGE } from 'constants/generic-messages';

import { ALERTS_DEFAULT_DISP_FREQUENCY } from 'components/common/form-elements/display-frequency-selector';
import ComposeTweet, {
  constructTweet,
} from '../../common/compose/ComposeTweet';
import ComposeScreens, {
  AlertScreenInitialValues,
} from '../../common/compose/ComposeScreens';
import InternalLog from '../../common/internal-log/internal-log';
import * as S from './index.styled';
import HR from '../../common/HR';

import {
  ComposeFormData,
  TImpact,
  RouteMention,
  ScreenTarget,
  AlertScreenTargeting,
} from '../../../types';
import { useMessageCreation } from '../../../hooks';
import PageMeta from '../../common/PageMeta';
import {
  isTrain,
  isBus,
  isBridgeTunnel,
  isOutfront,
  isRailRoad,
} from '../../../utils/feed-switches';
import { getMessageTypeDescriptionsByFeed } from '../../../utils/message-type-display';
import { useFeedId } from '../../../contexts/FeedId';
import ConfirmModal from '../../common/confirm-modal';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import ErrorModal from '../../common/error-modal';
import {
  WEB_IMPACTS_NAME,
  WEB_TARGETED_ROUTES_NAME,
  WEB_TARGETED_STOPS_NAME,
  WEB_EXPIRATION_NAME,
  TWEET_CUSTOMIZED_NAME,
  SCREEN_TARGETED_ROUTES_NAME,
  SCREEN_CUSTOMIZED_NAME,
  SCREEN_TARGETING_NAME,
  EMAIL_SUBJECT_NAME,
  WEB_BODY_NAME,
  EMAIL_BODY_NAME,
  WEB_BODY_ADDITIONAL_NAME,
  AFFECTED_STOPS_NAME,
  AFFECTED_STOPS_EDITED_NAME,
  EMAIL_TYPES_NAME,
  WEIGHT_FREQUENCY_NAME,
  INITIAL_SCREEN_CHOICE_NAME,
  SCREENS_NAME,
  SCREEN_TARGETING_EDITED,
  SCREEN_BODY_NAME,
  SCREEN_BODY_ADDITIONAL_NAME,
  SCREEN_TITLE_NAME,
  SMS_BODY_NAME,
} from '../../../constants/alerts';
import { scrollToErrors } from '../../../utils/compose-helpers';
import {
  GetEventById_event_Event_messages_MessagesConnection_nodes_Message_screenMessages_ScreenMessagesConnection_nodes_ScreenMessage as GetEventById_event_messages_nodes_screenMessages_nodes,
  GetEventById_event_Event_messages_MessagesConnection_nodes_Message_affectedStations_AffectedStation as GetEventById_event_messages_nodes_affectedStations,
} from '../../../generated/GetEventById';
import {
  EmailMessageTypeCodes,
  FeatureFlagName,
  MessageType,
  SchedulePriority,
  TargetType,
} from '../../../generated/global-types';
import { constructSMS } from 'utils/message-creation-utils';

export interface AlertFormDefaultValues {
  body: any;
  additionalInfo: any;
  emailBody: string;
  emailBodyMeta: any;
  subject: string;
  smsBody: string;
  sendEmailSms: boolean;
  sendWebAndApps: boolean;
  defaultImpacts: TImpact[];
  initialWebTargetedRoutes: RouteMention[];
  tweets: Tweet[];
  screenMessages: GetEventById_event_messages_nodes_screenMessages_nodes[];
  screenTargeting: ScreenTarget[][];
  showTweet: boolean;
  showScreens: boolean;
  affectedStations: GetEventById_event_messages_nodes_affectedStations[] | null;
  weightFrequency?: {
    priority: SchedulePriority;
    weight: number;
  }[];
  webExpiration: Date | undefined;
}

const Compose: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [showInternalLog, setShowInternalLog] = useState(true);
  const [surfaceErrors, setSurfaceErrors] = useState(false);

  const history = useHistory();
  const feedId = useFeedId();
  const location = useLocation<{ defaultValues?: AlertFormDefaultValues }>();

  const isOutfrontFeed = isOutfront(feedId);
  const isBridgeTunnelFeed = isBridgeTunnel(feedId);

  const showScreenTargeting = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );
  const isBusFeed = isBus(feedId);
  const defaultValues: AlertFormDefaultValues | undefined =
    location.state?.defaultValues;

  const isDuplicate = !!defaultValues;

  const messageTypeOptions = getMessageTypeDescriptionsByFeed(feedId);

  const getDefaultImpacts = (defaultImpacts: TImpact[]) => {
    return defaultImpacts.map((impact: TImpact) => ({
      isAgencyWide: impact.isAgencyWide,
      // Validate that the message type is currently active for alerts
      messageType: messageTypeOptions.some(
        (option) => option.value === impact.messageType,
      )
        ? impact.messageType
        : ('' as MessageType),
      routes: impact.routes,
    }));
  };

  const defaultImpacts = defaultValues?.defaultImpacts
    ? getDefaultImpacts(defaultValues.defaultImpacts)
    : ([{ messageType: '', routes: [], isAgencyWide: false }] as any);

  const [showTwitter, setShowTwitter] = useState(
    isOutfrontFeed ? false : (defaultValues?.showTweet ?? false),
  );
  const [showScreens, setShowScreens] = useState(
    isRailRoad(feedId) ? false : (defaultValues?.showScreens ?? true),
  );
  const [showWebAndApps, setShowWebAndApps] = useState(
    defaultValues?.sendWebAndApps ?? true,
  );
  const [showEmailAndSms, setShowEmailAndSms] = useState(
    isOutfrontFeed ? false : (defaultValues?.sendEmailSms ?? true),
  );

  const hasNoOutlets =
    !showTwitter && !showScreens && !showWebAndApps && !showEmailAndSms;

  const {
    isSubmitting,
    onSubmit,
    isDraft,
    error: creationError,
    clearError: clearCreationError,
  } = useMessageCreation<ComposeFormData>({
    onComplete: (feedId, eventId) =>
      history.push(`/${feedId}/events/${eventId}`),
    enabledOutlets: {
      twitter: showTwitter,
      screens: showScreens,
      email: showEmailAndSms,
      apps: showWebAndApps,
    },
  });

  const screens = defaultValues
    ? defaultValues.screenTargeting.map((v, i) => {
        const initialScreenChoice = v
          .flatMap(({ layouts }) => layouts)
          .includes(TargetType.DUP)
          ? AlertScreenTargeting.SIDEWALK_SCREEN
          : AlertScreenTargeting.STATION_SCREEN;
        return {
          [SCREEN_CUSTOMIZED_NAME]: isDuplicate,
          [SCREEN_TARGETING_NAME]: defaultValues.screenTargeting[i],
          [SCREEN_BODY_NAME]: undefined,
          [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
          [WEIGHT_FREQUENCY_NAME]: {
            priority:
              defaultValues.weightFrequency?.[i]?.priority ??
              ALERTS_DEFAULT_DISP_FREQUENCY.priority,
            weight:
              defaultValues.weightFrequency?.[i]?.weight ??
              ALERTS_DEFAULT_DISP_FREQUENCY.weight,
          },
          [INITIAL_SCREEN_CHOICE_NAME]: initialScreenChoice,
          [SCREEN_TARGETING_EDITED]: false,
          [SCREEN_TITLE_NAME]:
            defaultValues?.screenMessages?.[i]?.title ?? undefined,
        };
      })
    : [
        {
          [SCREEN_CUSTOMIZED_NAME]: isDuplicate,
          [SCREEN_BODY_NAME]: undefined,
          [SCREEN_BODY_ADDITIONAL_NAME]: undefined,
          [SCREEN_TARGETING_NAME]: [
            {
              routeId: '',
              options: [],
              layouts: getAlertDefaultScreenTargetTypes(feedId),
              tags: {},
            },
          ],
          [WEIGHT_FREQUENCY_NAME]: {
            priority: ALERTS_DEFAULT_DISP_FREQUENCY.priority,
            weight: ALERTS_DEFAULT_DISP_FREQUENCY.weight,
          },
          [INITIAL_SCREEN_CHOICE_NAME]: undefined,
          [SCREEN_TARGETING_EDITED]: false,
        },
      ];

  const tweetFormattingOptions = {
    replacements: LONG_GTFS_TEXT_REPLACEMENTS,
    feedId,
    bracketCustomIcons: true,
  };

  const formMethods = useForm<ComposeFormData>({
    mode: 'onBlur',
    defaultValues: {
      [EMAIL_BODY_NAME]: defaultValues?.emailBody || '',
      [EMAIL_SUBJECT_NAME]: defaultValues?.subject || '',
      [EMAIL_TYPES_NAME]: [EmailMessageTypeCodes.RTSCH],
      [SMS_BODY_NAME]: defaultValues?.smsBody || '',
      [WEB_IMPACTS_NAME]: defaultImpacts,
      [WEB_TARGETED_ROUTES_NAME]: defaultValues?.initialWebTargetedRoutes || [],
      [WEB_TARGETED_STOPS_NAME]: [],
      [TWEET_CUSTOMIZED_NAME]: (() => {
        if (!defaultValues || !isDuplicate) {
          return false;
        }
        const { tweets, body, additionalInfo } = defaultValues;
        return (
          tweets?.length > 0 &&
          tweets?.[0]?.text !==
            constructTweet(
              formatMessageDoc(
                fromJSONToNode(body?.schema, body?.doc),
                tweetFormattingOptions,
              ),
              additionalInfo
                ? formatMessageDoc(
                    fromJSONToNode(additionalInfo?.schema, additionalInfo?.doc),
                    tweetFormattingOptions,
                  )
                : undefined,
            )
        );
      })(),
      [SCREEN_TARGETED_ROUTES_NAME]: [],
      [SCREENS_NAME]: screens,
      [AFFECTED_STOPS_NAME]: getDefaultAffectedStations(
        defaultValues?.affectedStations,
      ),
      [WEB_EXPIRATION_NAME]: defaultValues?.webExpiration,
    },
  });

  const {
    tweets,
    setText: setTweetText,
    setAttachments: setTweetAttachments,
    setQuoteId: setTweetQuoteId,
    setReplyId: setTweetReplyId,
    addTweet,
    removeTweet,
  } = useTweetThreadState(defaultValues?.tweets);

  if (surfaceErrors && Object.keys(formMethods.errors).length > 0) {
    scrollToErrors(formMethods.errors);
    setSurfaceErrors(false);
  }

  // Used for storing the alerts message and additional info text
  // to be concatinated into a single tweet. First empty string is
  // for the message and the second is for the additional info
  const autofilledTweetsText = React.useRef<[string, string]>(['', '']);

  return (
    <S.ComposeContainer>
      <Prompt
        when={
          formMethods.formState.dirty && !isCancelModalOpen && !isSubmitting
        }
        message={DISCARD_CHANGES_MESSAGE}
      />
      <PageMeta title="Compose Alert" />
      <BackOnlyPageHeading
        back={{ to: `/${feedId}`, title: 'Back to Alerts' }}
      />
      <form
        onSubmit={(event) =>
          formMethods.handleSubmit((data) =>
            onSubmit(data, checkIfDraft(event)),
          )(event)
        }
      >
        <S.FormElementsWrapper>
          <AlertFormHeading>Compose Alert</AlertFormHeading>
          <FormContext {...formMethods}>
            <AlertMessageLeader
              additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
              messageName={WEB_BODY_NAME}
              impactsName={WEB_IMPACTS_NAME}
              targetedStopsName={WEB_TARGETED_STOPS_NAME}
              targetedRoutesName={WEB_TARGETED_ROUTES_NAME}
              affectedStopsEditedName={AFFECTED_STOPS_EDITED_NAME}
              affectedStopsName={AFFECTED_STOPS_NAME}
              onMessageChange={(state) => {
                if (!formMethods.getValues()[TWEET_CUSTOMIZED_NAME]) {
                  autofilledTweetsText.current = [
                    formatMessageDoc(state.doc, tweetFormattingOptions),
                    autofilledTweetsText.current[1],
                  ];

                  setTweetText(
                    0,
                    constructTweet(...autofilledTweetsText.current),
                  );
                }
              }}
              onAdditionalMessageChange={(state) => {
                if (!formMethods.getValues()[TWEET_CUSTOMIZED_NAME]) {
                  autofilledTweetsText.current = [
                    autofilledTweetsText.current[0],
                    formatMessageDoc(state.doc, tweetFormattingOptions),
                  ];

                  setTweetText(
                    0,
                    constructTweet(...autofilledTweetsText.current),
                  );
                }
              }}
              onAdditionalMessageToggle={(enabled) => {
                if (
                  !enabled &&
                  !formMethods.getValues()[TWEET_CUSTOMIZED_NAME]
                ) {
                  autofilledTweetsText.current = [
                    autofilledTweetsText.current[0],
                    '',
                  ];

                  setTweetText(0, autofilledTweetsText.current[0]);
                }
              }}
              initMessageHtml={defaultValues?.body?.html || '<p></p>'}
              initAdditionalMessageHtml={
                defaultValues?.additionalInfo?.html || '<p></p>'
              }
              shouldAutoPopulateTargeting={!isDuplicate}
              hasNoOutlets={hasNoOutlets}
            />
            <HR />
            <AlertWebAndApps
              title={isBusFeed ? 'Web, Apps, & Screens' : 'Web & Apps'}
              additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
              expirationName={WEB_EXPIRATION_NAME}
              messageName={WEB_BODY_NAME}
              onToggle={(enabled) => setShowWebAndApps(enabled)}
              show={showWebAndApps}
            />
            <HR />
            <AlertEmailAndSubject
              additionalMessageName={WEB_BODY_ADDITIONAL_NAME}
              emailBodyName={EMAIL_BODY_NAME}
              messageName={WEB_BODY_NAME}
              smsName={SMS_BODY_NAME}
              subjectName={EMAIL_SUBJECT_NAME}
              webImpactsName={WEB_IMPACTS_NAME}
              show={showEmailAndSms}
              onToggle={(enabled) =>
                !isOutfrontFeed && setShowEmailAndSms(enabled)
              }
              isDuplicate={isDuplicate}
              initBody={
                isDuplicate
                  ? defaultValues?.emailBodyMeta || defaultValues?.emailBody
                  : undefined
              }
              initSubject={defaultValues?.subject}
              initSms={
                isDuplicate
                  ? defaultValues?.smsBody ||
                    constructSMS(
                      feedId,
                      fromJSONToNode(
                        defaultValues!.body.schema,
                        defaultValues!.body.doc,
                      ),
                    )
                  : undefined
              }
            />
            <HR />
            {!isBridgeTunnelFeed && (
              <ComposeTweet
                show={showTwitter}
                onToggle={() =>
                  !isOutfrontFeed &&
                  setShowTwitter((prevShowTwitter) => !prevShowTwitter)
                }
                tweets={tweets}
                setText={setTweetText}
                setAttachments={setTweetAttachments}
                addTweet={addTweet}
                removeTweet={removeTweet}
                setQuoteId={setTweetQuoteId}
                setReplyId={setTweetReplyId}
              />
            )}
          </FormContext>
        </S.FormElementsWrapper>
        <S.FormElementsWrapper>
          <FormContext {...formMethods}>
            {showScreenTargeting && isTrain(feedId) && (
              <ComposeScreens
                show={showScreens}
                onToggle={() =>
                  setShowScreens((prevShowScreens) => !prevShowScreens)
                }
                isDuplicate={isDuplicate}
                initialValues={defaultValues?.screenMessages?.map<AlertScreenInitialValues>(
                  (sm) => ({
                    initialAdditionalInfoHtml: sm.additionalInfoHtml,
                    initialBodyHtml: sm.bodyHtml,
                    initialTitle: sm.title,
                  }),
                )}
              />
            )}
          </FormContext>
        </S.FormElementsWrapper>
        <S.FormElementsWrapper>
          <FormContext {...formMethods}>
            <InternalLog
              onClick={() => setShowInternalLog(!showInternalLog)}
              isOpen={showInternalLog}
            />
          </FormContext>
        </S.FormElementsWrapper>
        <S.ButtonContainer>
          <AlertFormButtons
            isSubmittingDraftAlert={isSubmitting && isDraft}
            isSubmittingActiveAlert={isSubmitting && !isDraft}
            onCancel={() => setIsCancelModalOpen(true)}
            onSubmit={() => setSurfaceErrors(true)}
          />
        </S.ButtonContainer>
      </form>
      <ConfirmModal
        isOpen={isCancelModalOpen}
        title="Cancel Message"
        message={DISCARD_CHANGES_MESSAGE}
        onDismiss={() => setIsCancelModalOpen(false)}
        onConfirm={() => history.push(`/${feedId}`)}
      />
      <ErrorModal
        isOpen={!!creationError}
        title="Message Failed to Post"
        onDismiss={() => clearCreationError()}
        message="Sorry, something went wrong while attempting to post this message."
        includeAssistanceMessage
      />
    </S.ComposeContainer>
  );
};

export default Compose;
